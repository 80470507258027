<template>
    <span v-if="profile.dev">
        <v-card class="mt-3 pa-3 mx-auto" max-width="600">  
            <v-card-title primary-title class="justify-center">
                <h1 class="text-center mb-3">Dev Tools</h1>
            </v-card-title>
            <v-card-text class="d-flex flex-column align-center justify-center">
                <v-btn color="error" outlined @click="test">test !</v-btn>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import notifier from '@/assets/functions/notifier.js'

export default {
    name: "DevTools",
    props: ["user", "profile", "userSession"],
    methods: {
        async test() {
            console.log("test")
        },
    },
}
</script>
